import React, { useEffect, useState } from 'react';
import { isEmpty, isUndefined, head } from 'lodash';
import { bcApi } from '../../helpers/bigcommerce';

import AddressCard from '../../components/molecules/AddressCard/AddressCard';
import AddressForm from '../../components/molecules/AddressForm/AddressForm';
import AccountPageWrapper from '../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import styles from './addresses.module.css';

const Addresses = ({ customerId }) => {
  const [addresses, setAddresses] = useState([]);
  const [actived, setActived] = useState(null);
  const [fetched, toggleFetched] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const addAddress = () => setActived({});

  const editAddress = address => setActived(address);

  const cancelUpdate = () => setActived(null);

  const handleResponse = (response, isNew) => {
    const address = head(response.data);
    if (address) {
      let updated = isNew
        ? [...addresses, address]
        : addresses.map(a => (a.id === address.id ? address : a));
      updated.filter(x => x);
      setAddresses(updated);
    }
  };

  const saveAddress = address => {
    if (isEmpty(address)) {
      return;
    }
    setIsSaving(true);
    if (isUndefined(address.id)) {
      bcApi(`customers/addresses`, 'POST', [
        { ...address, customer_id: customerId }
      ]).then(({ response, status }) => {
        if (status === 200 && !isEmpty(response.data)) {
          handleResponse(response, true);
        }
        setActived(null);
        setIsSaving(false);
      });
    } else {
      // update address
      bcApi(`customers/addresses`, 'PUT', [address]).then(
        ({ response, status }) => {
          if (status === 200 && !isEmpty(response.data)) {
            handleResponse(response);
          }
          setActived(null);
          setIsSaving(false);
        }
      );
    }
  };

  const removeAddress = addressId => {
    // delete address
    const endpoint = `customers/addresses?id:in=${addressId}`;
    bcApi(endpoint, 'DELETE').then(response => {
      if (response.status === 204) {
        setAddresses(addresses.filter(x => x.id !== addressId));
      }
    });
  };

  useEffect(() => {
    if (!fetched && customerId > 0) {
      bcApi(`customers/addresses?customer_id:in=${customerId}`).then(
        ({ response, status }) => {
          if (typeof response === 'object' && status === 200) {
            setAddresses(response.data);
          }
          toggleFetched(true);
        }
      );
    }
  }, [fetched, customerId]);

  return (
    <>
      {fetched && addresses && addresses.length === 0 && (
        <span>You have no saved addresses</span>
      )}

      {fetched && !actived && (
        <div className={styles.container}>
          {addresses?.map((address, addressIndex) => (
            <AddressCard
              address={address}
              key={addressIndex}
              onEdit={() => editAddress(address)}
              onDelete={() => removeAddress(address.id)}
            />
          ))}
          <AddressCard onAddAddress={addAddress} />
        </div>
      )}

      <AddressForm
        address={actived}
        visible={!!actived}
        isSaving={isSaving}
        onSubmit={saveAddress}
        onCancel={cancelUpdate}
      />

      {!fetched && <span>Fetching your stored addresses...</span>}
    </>
  );
};

const AddressesOutput = () => (
  <AccountPageWrapper metaTitle="Account - Addresses" title="Addresses">
    <Addresses />
  </AccountPageWrapper>
);

export default AddressesOutput;
