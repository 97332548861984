import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Button from '../../atoms/Button/Button';
import Loader from '../../atoms/Loader/Loader';
import { bcApi } from '../../../helpers/bigcommerce';
import { useScript } from '../../../helpers/general'
import styles from './AddressForm.module.css';

require('dotenv').config();

const defaultAddress = {
  address1: '',
  address2: '',
  city: '',
  company: '',
  country_code: '',
  first_name: '',
  last_name: '',
  phone: '',
  postal_code: '',
  state_or_province: ''
};

const required = {
  first_name: 'First Name',
  city: 'City',
  country_code: 'County',
  state_or_province: 'State/Province',
  last_name: 'Last Name',
  address1: 'Address 1',
  postal_code: 'Postcode/Zip'
};

let autoComplete;

const AddressForm = ({ address, onCancel, onSubmit, visible, isSaving }) => {
  const [addressData, setAddressData] = useState(defaultAddress);
  const [countries, setCountries] = useState([]);
  const [countryStates, setCountryStates] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [formErr, setFormErr] = useState({});
  const [query, setQuery] = useState('');
  const [acInit, setAcInit] = useState(false);
  const [autocompleteData, setAutocompleteData] = useState({});

  const autoCompleteRef = useRef(null);

  const fetchStates = countryIso => {
    setIsFetching(true);
    let countryObject = false;
    countries.map(country => {
      if (country.country_iso2 === countryIso) {
        countryObject = country;
      }
      return true;
    });

    const endpoint = countryObject.states.resource.substring(1);
    bcApi(`${endpoint}?limit=250`, 'GET', null, 2).then(
      ({ response, status }) => {
        if (status === 200) {
          setCountryStates(response);
        } else {
          setCountryStates([]);
        }
        setIsFetching(false);
      }
    );
  };

  const handleUpdateValue = e => {
    const { value, name } = e.target;
    console.log(name, value);
    console.log(addressData);
    if (name === 'country_code') {
      setAddressData({ ...addressData, [name]: value, state_or_province: '' });
      fetchStates(value);
    } else {
      setAddressData({ ...addressData, [name]: value });
    }
    window.addressData = addressData
    setFormErr({});
  };

  const handleSubmit = () => {
    const errObj = {};
    let valid = true;
    Object.keys(required).forEach(key => {
      if (!addressData[key]) {
        valid = false;
        errObj[key] = `${required[key]} can not be blank.`;
      }
    });

    if (!valid) {
      setFormErr(errObj);
    } else {
      onSubmit(addressData);
    }
  };

  useEffect(() => {
    if (!isEmpty(address)) {
      setAddressData(address);
      if (address.country_code) {
        fetchStates(address.country_code);
      }
    }
    // eslint-disable-next-line
  }, [address]);

  useEffect(() => {
    if (!visible) {
      setAddressData(defaultAddress);
      setCountryStates([]);
      setFormErr({});
    }
  }, [visible]);

  useEffect(() => {
    setAddressData(data => ({...data, ...autocompleteData}));
  }, [autocompleteData]);

  useEffect(() => {
    bcApi(`countries?limit=250`, 'GET', null, 2).then(response => {
      if (response.status === 200) {
        setCountries(response.response);
      }
    });
  }, []);

  useScript(`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API}&libraries=places`, 'google', () => {
    if (typeof document !== 'undefined') {
      const autocompleteField = document.getElementById('autocompleteField');
      if (autocompleteField && !acInit) {
        setAcInit(true);
        initialiseAutoComplete(setQuery, setAutocompleteData, autoCompleteRef);
      }
    }
  });

  const initialiseAutoComplete = (updateQuery, updateAutocompleteData, autoCompleteRef) => {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { componentRestrictions: { country: "au" } }
    );
    autoComplete.setFields(["address_components", "formatted_address"]); // specify what properties we will get from API
    // add a listener to handle when the place is selected
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery, updateAutocompleteData)
    );
  }

  const handlePlaceSelect = async (updateQuery, updateAutocompleteData) => {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    const addressComponents = {};
    addressObject.address_components.map(component => {
      const types = component.types.filter(type => type !== 'political');
      addressComponents[types[0]] = component;
      return true;
    });

    updateAutocompleteData({
      address1: `${addressComponents.street_number?.long_name || ''} ${addressComponents.route?.long_name} `,
      city: addressComponents.locality?.long_name,
      country_code: addressComponents.country?.short_name,
      postal_code: addressComponents.postal_code?.long_name,
      state_or_province: addressComponents.administrative_area_level_1?.long_name
    });
  }

  if (!visible) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className="grid grid-50">
        <div className="formField required">
          <label htmlFor="first_name">First Name</label>
          <input
            type="text"
            name="first_name"
            required
            onChange={handleUpdateValue}
            value={addressData.first_name}
          />
          {formErr.first_name && (
            <span className="error">{formErr.first_name}</span>
          )}
        </div>
        <div className="formField required">
          <label htmlFor="last_name">Last Name</label>
          <input
            type="text"
            name="last_name"
            required
            onChange={handleUpdateValue}
            value={addressData.last_name}
          />
          {formErr.last_name && (
            <span className="error">{formErr.last_name}</span>
          )}
        </div>
      </div>
      {addressData.address1 === '' && (
        <>
          <div className="formField">
            <span className="label">Enter your address</span>
            <input type="text" autoComplete="new-password" id="autocompleteField" ref={autoCompleteRef} onChange={event => setQuery(event.target.value)} value={query} />
          </div>
          <div className="formField">
            <span className="label">Or enter below:</span>
          </div>
        </>
      )}
      <div className="formField required">
        <label htmlFor="address1">Address 1</label>
        <input
          type="text"
          name="address1"
          required
          onChange={handleUpdateValue}
          value={addressData.address1}
        />
        {formErr.address1 && <span className="error">{formErr.address1}</span>}
      </div>
      <div className="formField">
        <label htmlFor="address2">Address 2</label>
        <input
          type="text"
          name="address2"
          onChange={handleUpdateValue}
          value={addressData.address2}
        />
      </div>
      <div className="grid grid-50">
        <div className="formField required">
          <label htmlFor="city">City</label>
          <input
            type="text"
            name="city"
            required
            onChange={handleUpdateValue}
            value={addressData.city}
          />
          {formErr.city && <span className="error">{formErr.city}</span>}
        </div>
        <div className="formField required">
          <label htmlFor="postal_code">Postcode/Zip</label>
          <input
            type="text"
            name="postal_code"
            required
            onChange={handleUpdateValue}
            value={addressData.postal_code}
          />
          {formErr.postal_code && (
            <span className="error">{formErr.postal_code}</span>
          )}
        </div>
      </div>
      <div className="grid grid-50">
        <div className="formField required">
          <label htmlFor="country_code">Country</label>
          {!isEmpty(countries) && (
            <select
              required
              name="country_code"
              onChange={handleUpdateValue}
              onBlur={handleUpdateValue}
              value={addressData.country_code}
            >
              <option>Select a country</option>
              {countries.map((country, countryIndex) => (
                <option key={countryIndex} value={country.country_iso2}>
                  {country.country}
                </option>
              ))}
            </select>
          )}
          {formErr.country_code && (
            <span className="error">{formErr.country_code}</span>
          )}
        </div>
        <div className="formField required">
          <label htmlFor="state_or_province">State/Province</label>
          {!isEmpty(countryStates) && (
            <select
              name="state_or_province"
              required
              value={addressData.state_or_province}
              onChange={handleUpdateValue}
              onBlur={handleUpdateValue}
            >
              <option>Select a state</option>
              {countryStates.map((state, stateIndex) => (
                <option key={stateIndex} value={state.state}>
                  {state.state}
                </option>
              ))}
            </select>
          )}
          {isEmpty(countryStates) && (
            <input
              type="text"
              name="state_or_province"
              required
              value={addressData.state_or_province}
              onChange={handleUpdateValue}
            />
          )}
          {isFetching && (
            <div className={styles.spinnerWrapper}>
              <Loader />
            </div>
          )}
          {formErr.state_or_province && (
            <span className="error">{formErr.state_or_province}</span>
          )}
        </div>
      </div>
      <div className="grid grid-50">
        <div className="formField">
          <label htmlFor="company">Company</label>
          <input
            type="text"
            name="company"
            onChange={handleUpdateValue}
            value={addressData.company}
          />
        </div>
        <div className="formField">
          <label htmlFor="phone">Phone</label>
          <input
            type="text"
            name="phone"
            onChange={handleUpdateValue}
            value={addressData.phone}
          />
        </div>
      </div>
      <div className="row">
        <Button
          type="span"
          level="primary"
          className={isSaving ? 'disabled' : ''}
          onClick={handleSubmit}
        >
          {!isSaving ? 'Save address' : 'Saving...'}
        </Button>
        <Button
          type="span"
          level="secondary"
          onClick={() => {setAcInit(false); setQuery(''); onCancel();}}
          className="ml-4"
        >
          Cancel
        </Button>
      </div>
      {isSaving && (
        <div className={styles.rootSpinner}>
          <Loader />
        </div>
      )}
    </div>
  );
};

AddressForm.propTypes = {
  address: PropTypes.shape({}),
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onAddAddress: PropTypes.func
};

AddressForm.defaultProps = {
  address: {},
  onEdit: () => null,
  onAddAddress: () => null,
  onDelete: () => null
};

export default AddressForm;
